@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.q-progress {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 25px !important;
  background-clip: padding-box;
  color: #f7bbbf;
}
.header {
  margin-bottom: 15px;
  margin-top: 10px;
}
.display {
  display: flex;
  justify-content: space-between;
}
.button-container {
  display: flex;
  justify-content: flex-end;
}
.store-button {
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #21ba45;
  color: #fff;
  text-align: center;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 20px;
  font-size: 1.6rem;
  transition: all 200ms ease;
}
.store-button--no-margin {
  margin: 0;
}
.store-button:hover {
  background-color: #198f35;
  color: #fff;
  transition: all 200ms ease;
}
/*# sourceMappingURL=src/app/pages/store/components/installation.css.map */