@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.q-list-header {
  font-size: 20px !important;
}
.q-item-main {
  margin-left: 30px !important;
  font-size: 20px !important;
}
.display {
  display: flex;
  justify-content: flex-end;
}
.qlist {
  font-size: 1.3rem;
}
.header {
  margin-top: 10px;
  margin-bottom: 15px;
}
.store-button {
  margin: 20px;
  padding: 10px 15px;
  border-radius: 30px;
  background-color: #21ba45;
  color: #464749;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 200ms ease;
}
.store-button--no-margin {
  margin: 0;
}
.store-button--negative {
  background-color: #e91e63;
  color: #f3f1ef;
}
.store-button--negative:hover {
  background-color: #c1134e;
  transition: all 200ms ease;
}
.store-button--positive {
  background-color: #21ba45;
  color: #464749;
}
.store-button--positive:hover {
  background-color: #198f35;
  transition: all 200ms ease;
}
/*# sourceMappingURL=src/app/pages/store/components/permission.css.map */