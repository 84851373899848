
































































































@import '~variables'


.q-progress
  position relative
  display block
  overflow hidden
  width 100%
  height 25px !important
  background-clip padding-box
  color $primary

.header
  margin-bottom 15px
  margin-top 10px
.display
  display flex
  justify-content space-between

.button-container
  display flex
  justify-content flex-end

.store-button
  font-weight 700
  text-transform uppercase
  cursor pointer
  background-color $positive
  color white
  text-align center
  padding 10px 15px
  border-radius 30px
  margin 20px
  font-size $fs-h1
  transition all 200ms ease
  &--no-margin
      margin 0
  &:hover
      background-color $positive-darker
      color white
      transition all 200ms ease
