@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.card-container {
  width: 220px;
  display: flex;
  flex-direction: column;
}
.card-container .card-header {
  background-color: #fff;
  display: flex;
  width: 100%;
  height: 230px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.card-container .card-header .oapp-icon {
  width: 125px;
  height: 125px;
}
.card-container .card-header .oapp-name {
  text-transform: uppercase;
  margin-top: 30px;
  font-weight: 700;
  color: #000;
  font-size: 1.8rem;
}
.card-container .card-main {
  background-color: #464749;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.card-container .card-main .oapp-description {
  font-size: 1.3rem;
  padding: 20px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}
.card-container .card-main .store-button {
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fff;
  color: #464749;
  text-align: center;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 20px;
  font-size: 1.6rem;
  transition: all 200ms ease;
}
.card-container .card-main .store-button--no-margin {
  margin: 0;
}
.card-container .card-main .store-button:hover {
  background-color: #000;
  color: #fff;
  transition: all 200ms ease;
}
.card-container .card-main .store-button--disabled {
  background-color: #b4b2a9;
  cursor: default;
}
.card-container .card-main .store-button--disabled:hover {
  background-color: #b4b2a9 !important;
  color: #464749 !important;
}
.card-container .card-main .store-container {
  height: 90px;
  width: 100%;
  background: url("/assets/ui/PNG/trame-alpha.png");
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #b4b2a9;
}
/*# sourceMappingURL=src/app/pages/store/components/oapp-card.css.map */