


























































@import '~variables'

.q-list-header
  font-size 20px !important

.q-item-main
  margin-left 30px !important
  font-size 20px !important

.display
  display flex
  justify-content flex-end

.qlist
  font-size $fs-h2

.header
  margin-top 10px
  margin-bottom 15px

.store-button
  margin 20px
  padding 10px 15px
  border-radius 30px
  background-color $positive
  color $dark
  text-align center
  text-transform uppercase
  font-weight 700
  font-size $fs-h1
  cursor pointer
  transition all 200ms ease
  &--no-margin
    margin 0

.store-button--negative
  background-color $negative
  color $light
  &:hover
    background-color $negative-darker
    transition all 200ms ease

.store-button--positive
  background-color $positive
  color $dark
  &:hover
    background-color $positive-darker
    transition all 200ms ease
