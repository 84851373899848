









































@import '~variables'

.full
  padding 30px
  width 100%
  height 100%
