

















































@import '~variables'

.store
  position relative
  display flex
  flex-direction column
  justify-content center
  align-items center
  margin auto
  min-height 100%
  width 100%
  .q-btn
    position fixed
    right 20px
    bottom 10px
  .store-title
    display flex
    justify-content center
    align-items center
    margin-top $space-4
    margin-bottom $space-4
    padding $space-2
    width 170px
    border-radius 15px
    background-color black
    box-shadow $shadow-elevation-1
    color white
    font-weight 700
    font-size 2.3rem
    user-select none
    .oasis-logo
      padding 0
  .store-list
    display flex
    flex-wrap wrap
    justify-content center
    align-items center
    margin-bottom 50px
    .oapp-card
      margin 10px
